<template>
  <div>
      <a-table size="default" ref="table" rowKey="id" class="tbl-list"
        :locale="{emptyText: '暂无数据'}"
        :pagination="false"
        :columns="columns"
        :dataSource="dataSource"
        :customRow="customRow"
      >
        <template v-for="t in tag" :slot="t.column">
          <div v-if="t.tag" @click="showMore(t)">{{t.name}} <a-icon type="caret-down" /> </div>
          <span v-else>{{t.name}}</span>
        </template>
        <span slot="index" slot-scope="text,record,index">
          {{ index + 1 }}
        </span>
      </a-table>
    <a-modal
      class="tbl-pop"
      v-model="showPop"
      :title="popTitle"
      :destroyOnClose="true"
      :centered="true"
    >
      <template slot="footer">
        <a-button key="back" @click="showPop=false">关闭</a-button>
      </template>

        <a-table rowKey="name"
          :columns="columnsD"
          :data-source="list"
          :pagination="false"
          :defaultExpandAllRows="true"
          :scroll="{ y: 600 }"
        >
        <span slot="value" slot-scope="text,record,index">
          {{ text + record.unit }}
        </span>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import Vue from 'vue';
import { postForm } from '@/api'

export default {
    props: {
      query: {
        type: Object,
        default: () => {
          return {}
        }
      },
      dataList: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data() {
      return {
        dataSource: [],
        columns: [],
        tag: [],
        list: [],
        showPop: false,
        popTitle: '',
        columnsD: [
          {
            title: "环节",
            dataIndex: "name",
          },
          {
            title: "开口率",
            width: "70px",
            dataIndex: "rate",
            scopedSlots: { customRender: 'value' }
          },
        ]
      }
    },
    methods: {
      customRow(record, index) {
        return {
            style: {
              cursor: 'pointer'
            },
            on: {
               click: () => {
                  this.$emit('onClickRow', record, index);
               }
            }
          }
      },
      showMore(record) {
        this.popTitle = record.name;
        const params = {...this.query, id: record.id}
        postForm('/api/bsa_board/getLinkThreeFour', params).then(res => {
          this.showPop = true;
          const list = res.data || []
          this.list = list.map(o => ({...o, children: o.list}));
        });
      }
    },
    watch: {
      dataList(data) {
        const {title, list} = data;
        this.columns = [
          {
            title: " ",
            dataIndex: "name",
            width: "100px",
            //fixed: "left"
          },
          ...formatTblTitAS(title),
        ]
        this.tag = title.filter(o => o.tag);
        this.dataSource = (list || []).map(o => ({...o, ...o.data}));
      }
    }
}


function formatTblTitAS(titles) {
  const list = titles.map((o, i) => {
    const obj = {
      ...o,
      dataIndex: o.column,
      class: o.column,
      customHeaderCell: () => {
        return {
          style: {
            ...obj,
            'background-color': o.bg_color || '#E6F1F9'
          }
        }
      }
    }

    if (o.tag) {
      return {
        ...obj,
        slots: { title: o.column },
      }
    } else {
      return {
        ...obj,
        title: o.name,
      }
    }
  })
  let data = []
  list.forEach((o, i) => {
    const idx = data.findIndex(d => d.type == o.type);
    const types = list.filter(d => d.type == o.type);
    if (idx != -1) {
      data[idx].children = [
        ...data[idx].children,
        o
      ];
    } else {
      data = [
        ...data,
        {
          ...o,
          title: o.value || o.name,
          children: types.length > 1 ? [o] : null
        },
      ]
    }
  })
  return data;
}
</script>
<style lang="less" scope>
.tbl-list {
  th.score {
    white-space: break-spaces !important;
    word-wrap: break-word;
    .ant-table-header-column {
      width: 115px;
      text-align: center;
    }
  }
}
.tbl-pop {
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 0.2rem 0.5rem;
  }

  .ant-modal-footer {
    display: none;
  }
}
</style>
